import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

export const initMarquee = () => {
    const marquee = document.getElementById('RS-marquee__inner');
    const items = marquee.querySelectorAll('.RS-hero-marquee__item');
  
  gsap.set(items, { opacity: 0, y: 100 });


//   const t1 = gsap.timeline({ repeat: -1, defaults: { ease: "linear" }});
  

//   let keyframeDuration = 0.5;
//   let delayValue = 2;
//   var stagger = (keyframeDuration * 4 + delayValue); 
//   let repeatDelay = (stagger * (items.length - 1));

//   t1.from(items, {opacity: 0, y: 100, scale: .96, duration: 0.5, stagger:{
//         each:stagger,
//         repeat:-1,
//         repeatDelay:repeatDelay
//     }})
//     .to(items, {opacity: 1, y: 0, scale: 1, duration: 0.5, stagger:{
//         each:stagger,
//         repeat:-1,
//         repeatDelay:repeatDelay
//     }}, stagger)
//     .to(items, {opacity: 1, y: -100, scale: 1, duration: 0.5, delay: 2, stagger:{
//         each:stagger,
//         repeat:-1,
//         repeatDelay:repeatDelay
//     }}, stagger)
//     .to(items, {opacity: 0, y: -200, scale: .96, duration: 0.5, delay: 2, stagger:{
//         each:stagger,
//         repeat:-1,
//         repeatDelay:repeatDelay
//     }}, stagger)


//   animation.from(targets, {y:80, duration:duration, opacity:0, stagger:{
//     each:stagger,
//     repeat:-1,
//     repeatDelay:repeatDelay
// }})
//    .to(targets, {y:-80, duration:duration, opacity:0, stagger:{
//     each:stagger,
//     repeat:-1,
//     repeatDelay:repeatDelay
// }}, stagger)
// .to(targets, {y:-160, duration:duration, opacity:0, stagger:{
//     each:stagger,
//     repeat:-1,
//     repeatDelay:repeatDelay
// }}, stagger)



// const t1 = gsap.timeline({ repeat: -1, defaults: { ease: "linear" }});
  

// let keyframeDuration = 0.5;
// let delayValue = 2;
// var totalDuration = keyframeDuration * 4 + delayValue; 

// t1.to(items[0], {
//     keyframes: [
//         {opacity: 0, y: 100, scale: .96, duration: 0.5},
//         {opacity: 1, y: 0, scale: 1, duration: 0.5}, 
//         {opacity: 1, y: -100, scale: 1, duration: 0.5, delay: 2}, 
//         {opacity: 0, y: -200, scale: .96, duration: 0.5, delay: 2}
//        ]
//   }, 0
// );

//   t1.to(items[1], {
//     keyframes: [
//         {opacity: 0, y: 100, scale: .96, duration: 0.5},
//         {opacity: 1, y: 0, scale: 1, duration: 0.5}, 
//         {opacity: 1, y: -100, scale: 1, duration: 0.5, delay: 2}, 
//         {opacity: 0, y: -200, scale: .96, duration: 0.5, delay: 2}
//        ],
//   }, "-=3.25"
// );

// t1.to(items[2], {
//     keyframes: [
//         {opacity: 0, y: 100, scale: .96, duration: 0.5},
//         {opacity: 1, y: 0, scale: 1, duration: 0.5}, 
//         {opacity: 1, y: -100, scale: 1, duration: 0.5, delay: 2}, 
//         {opacity: 0, y: -200, scale: .96, duration: 0.5, delay: 2}
//        ],
//   }, "-=3.25"
// );

// t1.to(items[3], {
//     keyframes: [
//         {opacity: 0, y: 100, scale: .96, duration: 0.5},
//         {opacity: 1, y: 0, scale: 1, duration: 0.5}, 
//         {opacity: 1, y: -100, scale: 1, duration: 0.5, delay: 2}, 
//         {opacity: 0, y: -200, scale: .96, duration: 0.5, delay: 2}
//        ],
//   }, "-=3.25"
// );

// t1.to(items[4], {
//     keyframes: [
//         {opacity: 0, y: 100, scale: .96, duration: 0.5},
//         {opacity: 1, y: 0, scale: 1, duration: 0.5}, 
//         {opacity: 1, y: -100, scale: 1, duration: 0.5, delay: 2}, 
//         {opacity: 0, y: -200, scale: .96, duration: 0.5, delay: 2}
//        ],
//   }, "-=3.25"
// );

// t1.to(items[5], {
//     keyframes: [
//         {opacity: 0, y: 100, scale: .96, duration: 0.5},
//         {opacity: 1, y: 0, scale: 1, duration: 0.5}, 
//         {opacity: 1, y: -100, scale: 1, duration: 0.5, delay: 2}, 
//         {opacity: 0, y: -200, scale: .96, duration: 0.5, delay: 2}
//        ],
//   }, "-=3.25"
// );


    // const t1 = gsap.timeline({ repeat: -1, defaults: { ease: "linear" }});
    // const t2 = gsap.timeline({ repeat: -1, defaults: { ease: "linear" }});

    // t1.to(items[0], {
    //     keyframes: [
    //         {opacity: 0, y: 100, scale: .96, duration: 0.5},
    //         {opacity: 1, y: 0, scale: 1, duration: 0.5}, 
    //         {opacity: 1, y: -100, scale: 1, duration: 0.5, delay: 2}, 
    //         {opacity: 0, y: -200, scale: .96, duration: 0.5, delay: 2}
    //     ]
    // }, "0");

    // t2.to(items[1], {
    //     keyframes: [
    //         {opacity: 0, y: 100, scale: .96, duration: 0.5},
    //         {opacity: 1, y: 0, scale: 1, duration: 0.5}, 
    //         {opacity: 1, y: -100, scale: 1, duration: 0.5, delay: 2}, 
    //         {opacity: 0, y: -200, scale: .96, duration: 0.5, delay: 2}
    //     ],
    // }, "0");

    // t1.progress(0.5, true); // Set t1 progress to 50%
    // t2.progress(0, true); // Set t2 progress to 0%

    // t1.eventCallback("onUpdate", () => {
    //     if (t1.progress() >= 0.5) {
    //         t2.progress(t1.progress() - 0.5, true);
    //     }
    // });


  const tl = gsap.timeline({ repeat: -1, defaults: { ease: "power1.out" } });
  const durationValue = 0.6;
  const delayValue = 3;
  const overlapModifier = 0.15;
  const overlapOffset = (durationValue * 4 + delayValue * 2) / 2 - overlapModifier;


  let keyframes = [
    { opacity: 0, y: 100, scale: 0.96, duration: durationValue, ease: "sine.out" },
    { opacity: 1, y: 0, scale: 1, duration: durationValue, ease: "sine.out" },
    { opacity: 1, y: -100, scale: 1, duration: durationValue, delay: delayValue, ease: "sine.out" },
    { opacity: 0, y: -200, scale: 0.96, duration: durationValue, delay: delayValue, ease: "sine.out" }
  ];



  items.forEach((item, index) => {

    if(index != 5){
      tl.to(item, { keyframes }, index * overlapOffset);
    }
    
    if(index === 5){
      keyframes = [
        { opacity: 0, y: 100, scale: 0.96, duration: durationValue, ease: "sine.out" },
        { opacity: 1, y: 0, scale: 1, duration: durationValue, ease: "sine.out" },
        { opacity: 0, y: -100, scale: 0.96, duration: durationValue, delay: delayValue, ease: "sine.out" }
      ];
        tl.to(item, { keyframes }, index * overlapOffset); 
    }
  });


  ScrollTrigger.create({
    trigger: marquee,
    start: "top bottom",
    end: "bottom top",
    onEnter: () => tl.play(),
    onLeave: () => tl.pause(),
    onEnterBack: () => tl.play(),
    onLeaveBack: () => tl.pause()
  });

  // marquee.addEventListener('mouseenter', () => tl.pause());
  // marquee.addEventListener('mouseleave', () => tl.play());

}